import React from "react";
import SinglePlugin from "../components/SinglePlugin";

export default function PageSinglePost() {
  return (
    <div>
      <main className="page">
        <SinglePlugin />
      </main>
    </div>
  );
}
