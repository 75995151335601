import React from "react";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from '@vercel/speed-insights/react';
import "./styles/style.css";
import Router from "./utils/Router";
import { UserInfo } from "./utils/UserData";
import Header from "./components/Header";
import ScrollIndicator from "./components/ScrollIndicator";
import Footer from "./components/Footer";
import ReactGA from "react-ga";
ReactGA.initialize("G-Y7NEX6S5X0");


export default function App() {
  return (
    <>
    <Analytics/>
    <SpeedInsights/>
      <UserInfo>
        <Header />
        <ScrollIndicator />
        <Router />
        <Footer />
      </UserInfo>
    </>
  );
}
