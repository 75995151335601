import React from "react";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import Home from "../pages/Homepage";
import PageAbout from "../pages/PageAbout";
import Expertise from "../pages/SkillsPage";
import PageProjects from "../pages/PageProjects";
import PageContact from "../pages/PageContact";
import NotFound from "../pages/Page404";
import PageHireMe from "../pages/PageHireMe";
import PageBlog from "../pages/PageBlog";
import PageGithub from "../pages/PageGithub";
import PageSinglePost from "../pages/PageSinglePost";
import PageSingleProject from "../pages/PageSingleProject";
import PageSinglePlugin from "../pages/PageSinglePlugin";

export default function Router() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="blog" element={<PageBlog />} />
      <Route path="/post/:slug/:id" element={<PageSinglePost />} />
      <Route path="/plugins/api-renderflex" element={<PageSinglePlugin />} />
      <Route path="code-samples" element={<PageGithub />} />
      <Route path="projects" element={<PageProjects />} />
      <Route path="/project/:slug/:id" element={<PageSingleProject />} />
      <Route path="about" element={<PageAbout />} />
      <Route path="contact" element={<PageContact />} />
      <Route path="*" element={<NotFound />} />
      <Route path="hire-me" element={<PageHireMe />} />
      <Route path="expertise" element={<Expertise />} />
      <Route path="skills" element={<Expertise />} />
    </Routes>
  );
}
