import React from "react";
import { Link } from "react-router-dom";

function SinglePlugin() {
 // get data from WordPress API

  return (
    <article className="post_content">
      <ul className="grid_item_tags">
        <li className="grid_item_tags_tag">wordpress</li>
        <li className="grid_item_tags_tag">plugins</li>
      </ul>

      <h2 className="post_title">API RenderFlex Plugin</h2>

      <p class="desc">API RenderFlex is a versatile WordPress plugin designed to fetch and display data from external APIs with ease. Whether you're pulling in content from third-party services or custom APIs, this plugin gives you full control over how the data is rendered. Built-in support for both Gutenberg blocks and shortcodes, developers and site owners can easily customize the output using filters and hooks, making it reusable for a wide range of APIs and use cases. Perfect for those who want flexible, dynamic API integration without sacrificing customization.</p>

	<Link class="button" to="https://github.com/sarahcssiqueira/wp-api-renderflex/archive/HEAD.zip" 
					aria-label="Download sarahcssiqueira/wp-api-renderflex on GitHub">Download
    </Link>

	<Link class="link" to="https://github.com/sarahcssiqueira/wp-api-renderflex" 
					aria-label="Star sarahcssiqueira/wp-api-renderflex on GitHub">
		<i class="fa-regular fa-star"></i> Star Repo
	</Link>

	<Link class="link" to="https://github.com/sarahcssiqueira/wp-api-renderflex#readme" 
					aria-label="Readme sarahcssiqueira/wp-api-renderflex on GitHub">
	<i class="fa-solid fa-book-open"></i> Readme
	</Link>

	<Link class="link" to="https://github.com/sarahcssiqueira/wp-api-renderflex/fork" 
					aria-label="Fork sarahcssiqueira/wp-api-renderflex on GitHub">
	<i class="fa-solid fa-code-fork"></i> Fork Code
	</Link>

	<p class="desc">Happy coding!</p>

      
    </article>
  );
}

export default SinglePlugin;
